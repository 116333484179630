<template>
  <div class="preview dark" v-loading="flagObj.loading.value" element-loading-text="加载中..." :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="rgba(0, 0, 0, 0.8)">
    <vls-preview :ref="refObj.stage"
        v-if="flagObj.showPreview.value"
        :comps="screenData"
        :prjModelList="screenObj.prjModelList.value"
        :saveCallBack="screenSave"
        :uploadFile="uploadFile"
        :addPage="addPage"
        :updatePage="updatePage"
        :deletePage="deletePage"
        :modelCacheInterface="modelCacheInterface">
        <template #header>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item
              v-for="(n, i) in otherObj.project.value.routes"
              :key="i">
              <span v-if="n.disable" style="cursor: pointer; color: #1eaaff" @click="jumpSpace(n)">
              {{n.name}}</span>
              <span v-else style="color: #b3bfc7">
              {{n.name}}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </template>
        <template #nonEdit>
          <div class="preview-btn" @click="versionCreate(0)">
            <i class="icomoon-book"></i>版本
          </div>
          <div class="preview-btn" @click="versionCreate(1)">
            <i class="icomoon-camera"></i>快照
          </div>
          <div class="preview-btn" @click="shareShow()">
            <i class="icomoon-share2"></i>分享
          </div>
        </template>
      </vls-preview>
    <window :title="otherObj.authCode.title" :shadow="true" class="dark" @windowHide="shareHide()"
      windowId="version-share" v-if="otherObj.authCode.isShow">
      <template #body>
        <div class="line pd-20">
          <div class="insert-h">
            <div class="attr">有效时间</div>
            <div class="val">
              <el-radio-group v-model="otherObj.authCode.info.expireType" @change="changeExpire">
                <el-radio label="hour1">1小时</el-radio>
                <el-radio label="day1">1天</el-radio>
                <el-radio label="day3">3天</el-radio>
                <el-radio label="day7">7天</el-radio>
                <el-radio label="day14">14天</el-radio>
                <el-radio label="forever">永久</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="insert-h">
            <div class="attr">授权码</div>
            <div class="val" style="width: 25%">
              <el-input v-model="otherObj.authCode.info.code" maxlength="6" placeholder="请输入授权码" />
            </div>
          </div>
          <div class="insert-h">
            <div class="attr">克隆</div>
            <div class="val">
              <el-switch v-model="otherObj.authCode.info.type" inline-prompt active-text="是" inactive-text="否"
                :active-value="1" :inactive-value="0" style="margin: 4px 0" />
            </div>
          </div>
          <div class="insert-h" v-if="otherObj.authCode.info.type == 1">
            <div class="attr">克隆码</div>
            <div class="val" style="width: 25%">
              <el-input v-model="otherObj.authCode.info.cloneCode" maxlength="6" placeholder="请输入克隆码" />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button size="small" @click="shareHide()">取消</el-button>
          <el-button size="small" type="primary" @click="saveShareCode()">确定</el-button>
        </div>
      </template>
    </window>

    <window windowId="share-window" :shadow="true" class="dark" title="分享链接" @windowHide="shareUrlHide"
      v-if="otherObj.authCode.share.show">
      <template #body>
        <div class="line pd-20">
          <div class="insert-h">
            <div class="attr colon">链接地址</div>
            <div class="val">
              <el-input v-model="otherObj.authCode.share.url" disabled />
            </div>
          </div>
          <div class="insert-h">
            <div class="attr colon">时效</div>
            <div class="val" style="width: 25%">
              <el-input v-model="otherObj.authCode.info.expireName" disabled />
            </div>
          </div>
          <div class="insert-h">
            <div class="attr colon">授权码</div>
            <div class="val" style="width: 25%">
              <el-input v-model="otherObj.authCode.info.code" disabled />
            </div>
          </div>
          <div class="insert-h">
            <div class="attr colon">克隆码</div>
            <div class="val" style="width: 25%">
              <el-input v-model="otherObj.authCode.info.cloneCode" disabled />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button type="primary" @click="copy()">复制</el-button>
        </div>
      </template>
    </window>

    <window windowId="edit-version" :shadow="true" class="dark" :title="otherObj.version.title"
      @windowHide="versionHide" v-if="otherObj.version.isShow">
      <template #body>
        <div class="line pd-20">
          <el-form ref="versionForm" :model="otherObj.version.info" :rules="staticObj.rules">
            <el-form-item prop="name">
              <div class="insert-h">
                <div class="attr">版本名称</div>
                <div class="val">
                  <el-input v-model="otherObj.version.info.name" placeholder="请输入版本名称" />
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="description">
              <div class="insert-h">
                <div class="attr">描述(非必填)</div>
                <div class="val">
                  <el-input v-model="otherObj.version.info.description" placeholder="请输入描述" type="textarea"
                    :rows="4" />
                </div>
              </div>
            </el-form-item>

            <el-form-item prop="no">
              <div class="insert-h">
                <div class="attr">版本号</div>
                <div class="val">
                  <el-input v-model="otherObj.version.info.no" placeholder="请输入版本号" />
                </div>
              </div>
            </el-form-item>

            <div class="insert-h">
              <div class="attr">上传封面(非必填)</div>
              <div class="val">
                <el-upload action="#" list-type="picture-card" :file-list="otherObj.files"
                  :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :http-request="handleAvatarSuccess">
                  <el-icon class="icomoon-plus"></el-icon>
                </el-upload>
                <el-dialog v-model="flagObj.dialogVisible.value">
                  <div class="img-view">
                    <img :src="otherObj.dialogImageUrl.value" alt="" />
                  </div>
                </el-dialog>
              </div>
            </div>
          </el-form>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button @click="versionHide()">取消</el-button>
          <el-button type="primary" @click="versionSave(0)">保存</el-button>
        </div>
      </template>
    </window>

    <window windowId="edit-snapshot" :shadow="true" class="dark" :title="otherObj.snapshot.title"
      @windowHide="snapshotHide" v-if="otherObj.snapshot.isShow">
      <template #body>
        <div class="line pd-20">
          <el-form ref="snapshotForm" :model="otherObj.snapshot.info" :rules="staticObj.rules">
            <el-form-item prop="name">
              <div class="insert-h">
                <div class="attr">快照名称</div>
                <div class="val">
                  <el-input v-model="otherObj.snapshot.info.name" placeholder="请输入快照名称" />
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="description">
              <div class="insert-h">
                <div class="attr">描述(非必填)</div>
                <div class="val">
                  <el-input v-model="otherObj.snapshot.info.description" placeholder="请输入描述" type="textarea"
                    :rows="4" />
                </div>
              </div>
            </el-form-item>

            <el-form-item prop="no">
              <div class="insert-h">
                <div class="attr">版本号</div>
                <div class="val">
                  <el-input v-model="otherObj.snapshot.info.no" placeholder="请输入版本号" />
                </div>
              </div>
            </el-form-item>

            <div class="insert-h">
              <div class="attr">上传封面(非必填)</div>
              <div class="val">
                <el-upload action="#" list-type="picture-card" :file-list="otherObj.files"
                  :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :http-request="handleAvatarSuccess">
                  <el-icon class="icomoon-plus"></el-icon>
                </el-upload>
                <el-dialog v-model="flagObj.dialogVisible.value">
                  <div class="img-view">
                    <img :src="otherObj.dialogImageUrl.value" alt="" />
                  </div>
                </el-dialog>
              </div>
            </div>
          </el-form>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button @click="snapshotHide()">取消</el-button>
          <el-button type="primary" @click="versionSave(1)">保存</el-button>
        </div>
      </template>
    </window>

    <window windowId="config-window" :shadow="true" class="dark" title="操作配置" @windowHide="optConfig.show = false"
      v-if="optConfig.show">
      <template #body>
        <div class="line pd-20">
          <div class="insert">
            <div class="attr">吸附距离</div>
            <div class="val">
              <el-input-number size="small" v-model="optConfig.parameter.adsorption" :min="0" :max="10"
                style="width:100px;margin-right: 10px;" />
              <div class="word">移动组件时的吸附距离，设0则无吸附</div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button @click="optConfig.show = false">取消</el-button>
          <el-button type="primary" @click="updateAdsorption()">保存</el-button>
        </div>
      </template>
    </window>
  </div>
</template>

<script>
import FrameHeader from "components/content/platform/header/Header";
import ToolNav from "components/content/tools/ToolNav";
import Window from "components/common/forms/Window";
import { getSpaceOrPrjDetails, jumpRouteSetup } from "common/authBtn";
import _ from "underscore";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  getCurrentInstance,
} from "vue";
import { useMutataions } from "components/util/mapMutations";
import { VersionNo } from "common/validate";
import {
  init,
  adaptInitComp
} from "components/common/hook/preview";

export default {
  name: "Preview",
  components: {
    FrameHeader,
    ToolNav,
    Window,
  },
  data () {
    return {
    };
  },
  setup (props, { emit }) {
    const store = useStore();
    const router = useRouter();
    let optConfig = ref({
      show: false,
      parameter: {
        adsorption: 2
      }
    })
    let screenObj = {
      screen: ref({
        screenWidth: 1900,
        screenHeight: 1300,
        page: { "-1": { components: [] } }
      }),
      pageInfos: ref([]),
      selectPage: ref({ id: "-1" }),
      prjModelList: ref([]),
      operationObj: [], //用于撤销、恢复
    }, //存储大屏数据变量
      otherObj = {
        project: ref({}),
        dbList: ref([]),
        tabs: ref([
          {
            id: 0,
            name: "基础"
          },
          {
            id: 1,
            name: "数据"
          },
          {
            id: 2,
            name: "事件"
          },
          {
            id: 3,
            name: "参数"
          }
        ]),
        dialogImageUrl: ref(""),
        version: reactive({
          isShow: false,
          isEdit: false,
          title: "",
          info: {
            name: "",
            description: "",
            fileIds: [],
            no: ""
          }
        }),
        snapshot: reactive({
          isShow: false,
          isEdit: false,
          title: "",
          info: {
            name: "",
            description: "",
            fileIds: [],
            no: ""
          }
        }),
        authCode: reactive({
          title: "",
          isShow: false,
          info: {
            expireType: "day14",
            value: 14,
            code: "",
            cloneCode: ""
          },
          share: {
            url: ""
          }
        }),
        files: [],
        backList: "",
        comList: ""
      }, //其他数据
      flagObj = {
        loading: ref(true),
        isPreview: ref(false),
        ctrlKey: ref(false),
        isEdit: ref(false),
        dialogVisible: ref(false),
        dynamicEdit: ref(false),
        repeatCompEdit: ref(false),
        isScreenShot: ref(false),
        isListenerActive: ref(false),
        showPreview: ref(false)
      }, //状态变量
      refObj = {
        scene: ref(null),
        layers: ref(null),
        stage: ref(null),
        config: ref(null),
        oview: ref(null),
        oviewRe: ref(null),
        historyComps: {},
        compInfoList: ref([]),
        selectGroup: ref([]),
        currentNode: ref(""),
        board: ref(null),
        dynamicComp: ref({}),
        parentParams: ref({}),
        layoutWidths: {},
        repeatWidths: {},
        sceneHistoryComp: {},
        pageParams: ref({}),
        currentPage: ref([]),
        recordDynamic: ref({}),
        jumpParams: {},
        recordRows: {},
      }, //代理对象
      staticObj = {
        rules: {
          name: [
            { required: true, message: "版本名称不能为空", trigger: "blur" }
          ],
          no: [
            {
              required: true,
              message: "版本号不能为空(V1.0.0)",
              trigger: "blur"
            },
            {
              validator: VersionNo,
              message: "请输入正确格式版本号(V1.0.0)",
              trigger: "blur"
            }
          ]
        },
        unContainKeys: ref([
          "dv-active-ring-chart",
          "dv-capsule-chart",
          "dv-digital-flop",
          "dv-percent-pond",
          "dv-scroll-ranking-board",
          "dv-scroll-board",
          "dv-water-level-pond"
        ]),
        otherConfigKey: ref([
          "layout",
          "custom-scroll-num",
          "bim_earth"
        ]),
        mapKeys: ref([
          "choropleth",
          "static"
        ]),
        noAdvancedTypes: ref([
          "layout",
          "icon",
          "board",
          "dynamic-board",
          "custom-search1",
          "custom-layout1",
          "custom-calendar1",
          "vertical-line",
          "horizontal-line",
          "custom-scroll-num"
        ])
      }, screenData = ref({}); //静态数据
    //公共参数
    let global = store.state.Global.globalValues;
    let page = store.state.Global.pageValues;
    const $api = getCurrentInstance().appContext.config.globalProperties.$api;
    const $message = getCurrentInstance().appContext.config.globalProperties
      .$message;
    const $confirm = getCurrentInstance().appContext.config.globalProperties
      .$confirm;
    const axios = getCurrentInstance().appContext.config.globalProperties.axios;

    //获取dom对象
    const versionForm = ref(null),
      snapshotForm = ref(null);

    //封装mapMutations
    const mutation = useMutataions("Preview", [
      "setUserScale",
      "setPrjModelList",
      "setProject"
    ]);

    const getCompsFn = (callback) => {
      //此处为了解决，页面刷新导致vuex数据丢失问题
      if (global.length == 0) {
        global = store.state.Global.globalValues;
      }
      if (page && Object.keys(page).length == 0) {
        page = store.state.Global.pageValues;
      }
      getSpaceOrPrjDetails(store).then(res => {
        flagObj.loading.value = true;
        otherObj.project.value = res;
        mutation.setProject(res);
        $api.sp.getScreenByPrjId({ prjId: res.id }).then(data => {
          init({
            screenObj: screenObj,
            otherObj: otherObj,
            flagObj: flagObj,
            refObj: refObj,
            staticObj: staticObj,
            optConfig: optConfig,
            storeGlobal: global,
            storePage: page,
            commonAxios: axios,
            commonApi: $api,
            mutation: mutation,
            $message: $message
          });
          adaptInitComp(data, store, false, () => {
            // optConfig.value.parameter.adsorption = store.state.user.userInfo.adsorption;
            // getActiveComp(
            //   screenObj.screen.value.page[screenObj.selectPage.value.id]
            //     .components
            // );
            // otherObj.comList = JSON.stringify(screenObj.screen.value);
            // otherObj.backList = JSON.stringify(screenObj.screen.value);
            // if(callback) {
            //   callback();
            // }
            screenData.value = data;
            flagObj.showPreview.value = true;
            let times = setInterval(() => {
              flagObj.loading.value = false;
              clearInterval(times);
            }, 100)
          });
            // screenData.value = data;
            // flagObj.showPreview.value = true;
            // let times = setInterval(() => {
            //   if(refObj.loadCount == 0) {
            //     flagObj.loading.value = false;
            //     clearInterval(times);
            //   }
            // }, 100)
        });
      });
    };

    const save = (scrObj, callback) => {
      $api.sp.addPrjScreen(scrObj).then(data => {
          if (data && callback) {
            // screenObj.screen.value = JSON.parse(otherObj.backList);
            callback();
          }
        });
    };

    const screenSave = (scrObj) => {
      save(scrObj, () => {
        $message.success("保存成功!");
      });
    };

    /**
     * 版本、快照
     */
    const versionCreate = type => {
      otherObj.files = [];
      let ds;
      if (type == 0) {
        ds = otherObj.version;
        ds.title = "发布新版本";
      } else {
        ds = otherObj.snapshot;
        ds.title = "发布新快照";
      }

      ds.isEdit = false;
      ds.info = {
        name: "",
        description: "",
        prjId: otherObj.project.value.id,
        no: "",
        fileIds: [],
        type: type
      };
      ds.isShow = true;
      initForm(type);
    };

    const initForm = type => {
      if (type == 0 && versionForm.value) versionForm.value.clearValidate();
      else if(snapshotForm.value) snapshotForm.value.clearValidate();
    };

    const handleRemove = (file, fileList) => {
      otherObj.files.splice(0, otherObj.files.length, ...fileList);
    };

    const handlePictureCardPreview = file => {
      otherObj.dialogImageUrl.value = file.url;
      flagObj.dialogVisible.value = true;
    };

    const handleAvatarSuccess = data => {
      $api.file.uploadFile(data.file).then(res => {
        otherObj.files.push({ id: res.id, url: "/file/" + res.id });
      });
    };

    const versionHide = () => {
      otherObj.version.isShow = false;
    };

    const snapshotHide = () => {
      otherObj.snapshot.isShow = false;
    };

    const versionSave = type => {
      if (type == 0) {
        versionForm.value.validate(valid => {
          if (valid) {
            otherObj.version.info.fileIds = [];
            _.each(otherObj.files, v => {
              otherObj.version.info.fileIds.push(v.id);
            });
            $confirm("此操作将该项目当前数据生成版本记录, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }).then(() => {
              $api.sp.addVersion(otherObj.version.info).then(res => {
                if (res) {
                  $message.success("操作成功");
                  versionHide();
                }
              });
            });
          }
        });
      } else {
        snapshotForm.value.validate(valid => {
          if (valid) {
            otherObj.snapshot.info.fileIds = [];
            _.each(otherObj.files, v => {
              otherObj.snapshot.info.fileIds.push(v.id);
            });
            $confirm("此操作将该项目当前数据生成快照记录, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }).then(() => {
              $api.sp.addVersion(otherObj.snapshot.info).then(res => {
                if (res) {
                  $message.success("操作成功");
                  snapshotHide();
                }
              });
            });
          }
        });
      }
    };
    /**
     * end
     */

    /**
     * 分享
     */
    const shareShow = (type, item) => {
      if (type == 1) {
        otherObj.authCode.title = "授权预览";
        otherObj.authCode.isPreview = true;
      }

      otherObj.authCode.isShow = true;
      otherObj.authCode.info = {
        expireType: "day14",
        value: 14,
        code: "",
        cloneCode: "",
        prjId: otherObj.project.value.id,
        module: 2,
        type: 0
      };
    };

    const shareHide = () => {
      otherObj.authCode.isShow = false;
    };

    const saveShareCode = () => {
      let args = {
        ...otherObj.authCode.info,
        ...{
          key: screenObj.screen.value.id
        }
      };
      $api.sp.saveCode(args).then(data => {
        if (otherObj.authCode.info.code)
          otherObj.authCode.share.url =
            window.origin +
            "/ScreenPreview?shareId=" +
            data +
            "&code=" +
            otherObj.authCode.info.code;
        else
          otherObj.authCode.share.url =
            window.origin + "/ScreenPreview?shareId=" + data;
        otherObj.authCode.share.show = true;
        otherObj.authCode.info.expireName = getExpireName(
          otherObj.authCode.info.expireType
        );
        $message.success("操作成功");
      });
      shareHide();
    };

    const getExpireName = type => {
      let result = "";
      switch (type) {
        case "hour1":
          result = "1小时";
          break;
        case "day1":
          result = "1天";
          break;
        case "day3":
          result = "3天";
          break;
        case "day7":
          result = "7天";
          break;
        case "day14":
          result = "14天";
          break;
        case "forever":
          result = "永久";
          break;
      }
      return result;
    };

    const shareUrlHide = () => {
      otherObj.authCode.share.show = false;
    };

    const copy = () => {
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = otherObj.authCode.share.url;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");

      $message({
        type: "success",
        message: "分享链接复制成功"
      });
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    };

    const changeExpire = val => {
      let result = {};
      switch (val) {
        case "hour1":
          result = {
            expireType: "hour1",
            value: 1
          };
          break;
        case "day1":
          result = {
            expireType: "day1",
            value: 1
          };
          break;
        case "day3":
          result = {
            expireType: "day3",
            value: 3
          };
          break;
        case "day7":
          result = {
            expireType: "day7",
            value: 7
          };
          break;
        case "day14":
          result = {
            expireType: "day14",
            value: 14
          };
          break;
        case "forever":
          result = {
            expireType: "forever",
            value: -1
          };
          break;
        default:
          result = {
            expireType: "day14",
            value: 14
          };
          break;
      }
      otherObj.authCode.info = {
        ...otherObj.authCode.info,
        ...result
      };
    };
    /**
     * end
     */

    const jumpSpace = n => {
      if (otherObj.project.value.id != n.id) {
        jumpRouteSetup(emit, n).then(() => {
          router.push("/Main/SpaceDetail");
        });
      }
    };

    const updateAdsorption = () => {
      $api.auth.updateUserConfig({
        userId: store.state.user.userInfo.id,
        adsorption: optConfig.value.parameter.adsorption
      }).then(data => {
        $message.success('操作成功');
        optConfig.value.show = false;
        let info = store.state.user;
        info.userInfo.adsorption = optConfig.value.parameter.adsorption;
        store.commit("setUserInfo", info);
      })
    };

    const uploadFile = (file) => {
      return new Promise((res, rej) => {
        $api.file.uploadFile(file).then(data => {
          res(data);
        })
      })
    };

    const addPage = (obj) => {
      return $api.sp.addScreenPage(obj);
    };

    const updatePage = (obj) => {
      return  $api.sp.updateScreenPage(obj);
    };

    const deletePage = (item) => {
      return $api.sp.deleteScreenPage({ id: item.id });
    };

    const modelCacheInterface = (id) => {
      return $api.sp.getContentById({id:id});
    };

    onMounted(() => {
      emit("setCommonParams", () => {
        getCompsFn();
        // shortcutKey();
      });
    });

    onUnmounted(() => {
    });



    return {
      versionForm,
      snapshotForm,
      screenObj,
      otherObj,
      flagObj,
      refObj,
      staticObj,
      optConfig,
      screenData,

      screenSave,
      handleRemove,
      handlePictureCardPreview,
      handleAvatarSuccess,
      versionHide,
      versionSave,
      versionCreate,
      snapshotHide,

      shareShow,
      shareHide,
      saveShareCode,
      shareUrlHide,
      changeExpire,
      copy,

      jumpSpace,
      updateAdsorption,
      uploadFile,
      addPage,
      updatePage,
      deletePage,
      modelCacheInterface
    };
  }
};
</script>

<style lang="scss" scoped>
.preview {
  .preview-header {
    background: rgba(0, 0, 0, 0.4);
  }

  .preview-body {
    background: rgba(0, 0, 0, 0.6);
    display: flex;
  }
}
</style>
